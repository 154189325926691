
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Form } from 'vee-validate';

import toasts from '@/utils/toasts';
import { formDataErrors } from '@/utils/forms';

import FormField from '@/components/forms/FormField.vue';
import { Actions, Getters } from '@/store/enums/DrawEnums';
import { DrawSchema } from '@/schemas/draw.schemas';
import { Draw } from '@/models/DrawModel';

export default defineComponent({
  name: 'draw-update-form',
  emits: ['typeChange', 'typeBlur', 'drawUpdated', 'cancelForm'],
  props: {
    formValues: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    Form,
    FormField,
  },
  data: () => ({
    drawOptions: [
      {
        label: 'Monthly',
        value: 'monthly',
      },
      {
        label: 'Weekly',
        value: 'weekly',
      },
    ] as unknown as [],
    loading: false,
    isLoad: false,
    form: {} as typeof Form,
    formData: {} as unknown as Draw,
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.drawUpdateForm as typeof Form;
  },
  computed: {
    ...mapGetters({
      draw: Getters.GET_DRAW,
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),

    drawTypesOption() {
      return this.drawOptions;
    },

    formSchema() {
      return DrawSchema;
    },
  },
  methods: {
    ...mapActions({
      updateDraw: Actions.UPDATE_DRAW,
    }),

    addPrefix(name, prefix = '') {
      return prefix ? `${prefix}_${name}` : name;
    },

    handleCancel() {
      this.$emit('cancelForm');
    },

    resetForm() {
      (this.$refs.drawUpdateForm as typeof Form)?.resetForm();

      // Reset the form
      this.form.setValues(this.formData as unknown as Draw);
      this.formData = this.formData as unknown as Draw;
    },
    submitDrawForm(values) {
      if (this.loading) return;

      this.loading = true;

      this.formData = values;

      delete this.formData.draw_image;

      const payload = {
        id: this.draw.id,
        data: this.formData,
      };

      this.updateDraw(payload)
        .then(() => {
          this.loading = false;
          this.resetForm();

          this.$emit('drawUpdated');
        })
        .catch(() => {
          this.loading = false;

          if (this.actionErrors) {
            const { errors, message } = this.actionErrors;

            // Alert when creation request failed
            toasts.error(formDataErrors(errors, message), () => {
              return false;
            });
          }
        });
    },
    handleImageChange(value) {
      alert(value);
      this.setFieldValue('draw_image', value);
    },
    handleChangeType(value) {
      this.setFieldValue('type', value);
    },
    handleCloseType() {
      this.validateFormField('type');
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
  },

  watch: {
    async draw(value) {
      const drawData = await value;
      this.formData = {
        ...drawData,
        ...{
          draw_image: '',
        },
      };
      this.form.setValues(this.formData);
    },
  },
});
