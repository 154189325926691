
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import DrawCreateForm from '@/components/draws/DrawCreateForm.vue';
import { Draw } from '../../models/DrawModel';

export default defineComponent({
  name: 'draw-create-modal',
  emits: ['update:modelValue', 'formSubmitted', 'close'],
  props: {
    modelValue: { type: Boolean, default: false },
  },
  components: {
    Modal,
    DrawCreateForm,
  },
  data: () => ({
    drawData: {} as unknown as Draw,
  }),
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleDrawCreated() {
      this.$emit('formSubmitted');
    },

    handleClose() {
      this.$emit('close');
    },
  },
});
