
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Form } from 'vee-validate';

import toasts from '@/utils/toasts';
import { formDataErrors } from '@/utils/forms';

import FormField from '@/components/forms/FormField.vue';
import { Actions, Getters } from '@/store/enums/DrawEnums';
import { DrawSchema } from '@/schemas/draw.schemas';
import { Draw } from '@/models/DrawModel';

const initialValues = {
  type: '',
  draw_date: '',
  draw_time: '',
  prize: '',
  description: '',
  status: 'new',
  draw_image: '',
} as unknown as Draw;

export default defineComponent({
  name: 'draw-create-form',
  emits: ['typeChange', 'typeBlur', 'drawCreated', 'cancelForm'],
  props: {
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    Form,
    FormField,
  },
  data: () => ({
    drawOptions: [
      {
        label: 'Monthly',
        value: 'monthly',
      },
      {
        label: 'Weekly',
        value: 'weekly',
      },
    ] as unknown as [],
    loading: false,
    isLoad: false,
    form: {} as typeof Form,
    formData: initialValues,
    updateMode: false,
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.drawCreateForm as typeof Form;
  },
  computed: {
    ...mapGetters({
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),

    drawTypesOption() {
      return this.drawOptions;
    },

    formSchema() {
      return DrawSchema;
    },
  },
  methods: {
    ...mapActions({
      createDraw: Actions.CREATE_DRAW,
    }),

    handleCancel() {
      this.$emit('cancelForm');
    },

    resetForm() {
      (this.$refs.drawCreateForm as typeof Form)?.resetForm();

      // Reset the form
      this.form.setValues(initialValues);
      this.formData = initialValues;
    },
    submitDrawForm(values) {
      if (this.loading) return;

      this.loading = true;

      this.formData = values;

      this.formData.id = '';
      this.formData.status = 'new';

      this.createDraw(this.formData)
        .then(() => {
          this.loading = false;
          this.resetForm();

          this.$emit('drawCreated');
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors) {
            const { errors, message } = this.actionErrors;

            // Alert when creation request failed
            toasts.error(formDataErrors(errors, message), () => {
              return false;
            });
          }
        });
    },
    handleImageChange(value) {
      this.setFieldValue('draw_image', value);
    },
    handleChangeType(value) {
      this.setFieldValue('type', value);
    },
    handleCloseType() {
      this.validateFormField('type');
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    addPrefix(name, prefix = '') {
      return prefix ? `${prefix}_${name}` : name;
    },
  },
});
