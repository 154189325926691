
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { Actions, Getters, Mutations } from '@/store/enums/DrawEnums';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Chip from '@/components/Chip.vue';
import useBreakpoints from 'vue-next-breakpoints';

import DrawCreateModal from '@/components/draws/DrawCreateModal.vue';
import DrawUpdateModal from '@/components/draws/DrawUpdateModal.vue';
// import DrawWinnerModal from '@/components/draws/DrawWinnerModal.vue';
import { Draw } from '@/models/DrawModel';
import toasts from '@/utils/toasts';
import { capitalize } from '@/utils/text';

export default defineComponent({
  name: 'draw-table',
  components: {
    Card,
    DataTable,
    Chip,
    DrawCreateModal,
    DrawUpdateModal,
  },
  data: () => ({
    drawTable: '',
    loading: false,
    checkedCustomers: [],
    table: {
      total: 0,
      loading: false,
      items: [],
      search: '',
    },
    selectedDraw: false,
    showCreateModal: false,
    showUpdateModal: false,
  }),
  async mounted() {
    await this.loadData();
  },
  computed: {
    ...mapGetters({
      allData: Getters.GET_DRAWS,
      pagination: Getters.GET_PAGINATION,
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),
    isLoading() {
      return this.loading ? this.loading : false;
    },
    dataText() {
      return this.table.total > 1 ? 'draws' : 'draw';
    },
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        {
          name: 'Title',
          key: 'title',
          sortable: true,
        },
        {
          name: 'Type',
          key: 'type',
          sortable: true,
        },
        {
          name: 'Prize',
          key: 'prize',
          sortable: true,
          align: 'left',
        },
        {
          name: 'Draw Date',
          key: 'draw_date',
          sortable: true,
          align: 'left',
        },
        {
          name: 'Time',
          key: 'draw_time',
          sortable: true,
          align: 'center',
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
          hidden: this.isMobile,
          align: 'center',
        },
        {
          name: 'Action',
          key: 'action',
          sortable: true,
          align: 'center',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchAllData: Actions.FETCH_DRAWS,
      createData: Actions.CREATE_DRAW,
      updateData: Actions.UPDATE_DRAW,
      deleteData: Actions.DELETE_DRAW,
    }),
    ...mapMutations({
      setDraw: Mutations.SET_DRAW,
    }),
    capitalFirst(str) {
      return capitalize(str);
    },
    formatList() {
      return this.allData.map((item) => {
        return {
          id: item.id,
          bundle_enable: item.bundle_enable,
          bundle_factor: item.bundle_factor,
          days_left_text: item.days_left_text,
          description: item.description,
          draw_date: item.draw_date,
          draw_entries: item.draw_entries,
          draw_entries_count: item.draw_entries_count,
          draw_image: item.draw_image,
          draw_image_src: item.draw_image_src,
          draw_time: item.draw_time,
          draw_user_entries: item.draw_user_entries,
          draw_user_entries_count: item.draw_user_entries_count,
          metadata: item.metadata,
          prize: item.prize,
          status: item.status,
          time_counter: item.time_counter,
          title: item.title,
          type: item.type,
          winner_image: item.winner_image,
          winner_image_src: item.winner_image_src,
          winner_remarks: item.winner_remarks,
        };
      });
    },
    async loadData() {
      if (this.loading) return;
      this.loading = true;

      return await this.fetchAllData()
        .then(async () => {
          this.table.items = await this.formatList();
          this.table.total = await this.pagination.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => (this.loading = false));
    },

    getStatus(data: Draw) {
      if (data?.status == 'drawn') {
        return {
          type: 'primary',
          text: 'Drawn',
          value: true,
          textStatus: '',
        };
      }

      if (data?.status == 'open') {
        return {
          type: 'success',
          text: 'Open',
          value: true,
          textStatus: '',
        };
      }

      if (data?.status == 'initialized') {
        return {
          type: 'success',
          text: 'Initialized',
          value: true,
          textStatus: '',
        };
      }

      if (data?.status == 'new') {
        return {
          type: 'dark',
          text: 'New',
          value: true,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Closed', value: false };
    },

    onSearch(search) {
      this.table.search = search;
    },

    viewDetail(row: Draw) {
      this.setDraw(row);

      return this.$router.push({
        name: 'view-draw',
        params: { draw_id: row.id },
      });
    },

    editDetail(row: Draw) {
      if (['close', 'initialized', 'drawn'].includes(row.status)) return;

      this.setDraw(row);
      this.showUpdateModal = true;
    },

    confirmDeleteDraw(row: Draw) {
      if (['open', 'close', 'initialized', 'drawn'].includes(row.status))
        return;

      return toasts.confirm(
        `Are you sure you want to delete draw? You can't undo this action...`,
        (response) => {
          console.log('isConfirmed', response);
          if (response.isConfirmed) {
            this.deleteDraw(row);
          }
        }
      );
    },

    deleteDraw(row: Draw) {
      this.deleteData(row.id)
        .then(() => {
          toasts.success('Draw Successfully Deleted');

          this.loadData();
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionErrors;

          toasts.error(errors, message);
        });
    },

    handleFormSubimitted() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
      this.loadData();
    },

    toggleNew() {
      this.showCreateModal = true;
    },

    handleFormCancelled() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
    },
  },
});
