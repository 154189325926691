import { createValidationObjectSchema } from '@/utils/validations';

export const DrawSchema = createValidationObjectSchema({
  draw_date: {
    errorTextFieldLabel: 'draw date',
    required: true,
  },
  draw_time: {
    errorTextFieldLabel: 'draw time',
    required: true,
  },
  type: {
    errorTextFieldLabel: 'draw type',
    required: true,
  },
  prize: {
    errorTextFieldLabel: 'prize',
    required: true,
  },
  title: {
    errorTextFieldLabel: 'title',
    required: true,
  },
});

export const DrawWinnerSchema = createValidationObjectSchema({
  winner_image: {
    errorTextFieldLabel: 'winner photo',
    required: true,
  },
  winner_remarks: {
    errorTextFieldLabel: 'winner testament',
    required: true,
  },
});
