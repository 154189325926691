import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DrawUpdateForm = _resolveComponent("DrawUpdateForm")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialog) = $event)),
    onCloseModal: _ctx.handleClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("h2", null, "Update Draw: " + _toDisplayString(_ctx.draw?.title), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_DrawUpdateForm, {
        onDrawUpdated: _ctx.handleDrawUpdated,
        onCancelForm: _ctx.handleClose
      }, null, 8, ["onDrawUpdated", "onCancelForm"])
    ]),
    _: 1
  }, 8, ["modelValue", "onCloseModal"]))
}