
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Modal from '@/components/modals/Modal.vue';
import DrawUpdateForm from '@/components/draws/DrawUpdateForm.vue';
import { Draw } from '@/models/DrawModel';
import { Getters } from '@/store/enums/DrawEnums';

export default defineComponent({
  name: 'draw-update-modal',
  emits: ['formSubmitted', 'close', 'update:modelValue'],
  props: {
    modelValue: { type: Boolean, default: false },
  },
  data: () => ({
    formData: {} as unknown as Draw,
  }),
  components: {
    Modal,
    DrawUpdateForm,
  },
  computed: {
    ...mapGetters({
      draw: Getters.GET_DRAW,
    }),

    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleDrawUpdated() {
      this.$emit('formSubmitted');
    },

    handleClose() {
      this.$emit('close');
    },
  },
  watch: {
    dialog(value) {
      console.log('value', value);
    },
  },
});
